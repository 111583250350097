<template>
  <participation-view-content-container
    :current-stage="currentStage"
    :grey-background="true"
  >
    <div v-if="projectData && displayForum">
      <navigation-bar
        :background="'blue'"
        :link="{ name: 'participant-forum', params: { identifier: projectData.identifier } }"
        :label="'Foro'"
        class="slim"
      />
      <thread-detail
        v-if="projectData"
        :project-identifier="projectData.identifier"
      />
    </div>
  </participation-view-content-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import NavigationBar from '@/components/NavigationBar'
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import participationStages from '@/components/participation/participationStages'
import ThreadDetail from '@/components/forum/ThreadDetail.vue'

export default {
  name: 'ParticipantForumDetail',
  components: {
    NavigationBar,
    ParticipationViewContentContainer,
    ThreadDetail
  },
  computed: {
    ...mapGetters('participationProject', ['isCommunityProject']),
    ...mapGetters('forum', ['displayForum']),
    ...mapState('participationParticipant', ['participantData']),
    ...mapState('participationProject', ['projectData'])
  },
  created() {
    this.currentStage = participationStages.forum
  }
}
</script>
