<template>
  <div class="thread-heading">
    <avatar
      :size="30"
      :user-data="thread.author"
    />
    <div class="item-author">
      Publicado por<br>
      <span class="author">{{ authorFullName }}</span>
    </div>
    <div class="item-date">
      {{ formattedItemCreationDate }}
    </div>
  </div>
</template>

<script>
import { format, register } from 'timeago.js'

import { timeagoLocaleES } from '@/dates'
import Avatar from '../Avatar.vue'

export default {
  name: 'ThreadHeading',
  components: {
    Avatar
  },
  props: {
    thread: {
      type: Object,
      required: true
    }
  },
  computed: {
    authorFullName() {
      return `${this.thread.author.firstName} ${this.thread.author.lastName}`
    },
    formattedItemCreationDate() {
      register('es', timeagoLocaleES)
      return format(this.thread.created, 'es')
    }
  }
}
</script>

<style lang="scss" scoped>
.thread-heading {
  display: flex;
  margin-bottom: 15px;
  color: #333;
  text-align: left;

  .avatar {
    margin-right: 10px;
  }

  .item-author {
    font-size: 12px;
    line-height: 15px;

    .author {
      font-weight: 600;
    }
  }

  .item-date {
    flex-grow: 1;
    text-align: right;
    font-size: 12px;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
