<template>
  <div :class="['navigation-bar', `background-${background}`]">
    <div class="container is-widescreen">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column">
            <router-link
              v-slot="{ href, navigate }"
              :to="link"
            >
              <a
                :href="href"
                @click="navigate"
              >
                <back-arrow />
                {{ label }}
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackArrow from './icons/BackArrow.vue'

export default {
  name: 'NavigationBar',
  components: {
    BackArrow
  },
  props: {
    background: {
      type: String,
      required: false,
      default: 'transparent'
    },
    label: {
      type: String,
      required: true
    },
    link: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-bar {
  height: 60px;
  margin-bottom: 23px;

  &.slim {
    &, & a {
      line-height: 50px;
      height: 50px;
    }
  }

  &.background-white {
    background: #fff;
  }

  &.background-blue {
    &, & * {
      color: #fff;
    }

    background: $blue;
  }

  a {
    display: block;
    color: #424A57;
    line-height: 60px;

    &:hover {
      text-decoration: underline;
    }

    svg {
      content: "";
      display: inline-block;
      height: 14px;
      margin-right: 13px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }

  .standard-column {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
